<template>
  <div class="main-container">
      <Navigation/>
      <div class="container">
          
      </div>
  </div>
</template>
<script>
import Navigation from '../components/Navigation.vue'
export default {
  name: 'Home',
  components:{
    Navigation
  },
  methods:{
    
  }
}
</script>